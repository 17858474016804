
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .training-submission{
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px;
        .detail-title{
            cursor: pointer;
            &.detail-title:hover{
                color: #409eff;
            }
        }
        .detail-text{
            margin-bottom: 10px;
            ::v-deep img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        ::v-deep.el-dialog__header{
            border-bottom: 1px solid #eaeaea;
        }
        ::v-deep.el-dialog__footer{
            text-align: center;
        }
        ::v-deep.el-dialog__body{
            padding: 10px 20px;
        }
        ::v-deep.el-table__body-wrapper{
            height: calc(100% - 44px);
            overflow-y: auto;
        }
        ::v-deep.el-dialog__title{
            font-size: 14px;
        }
        ::v-deep .el-dialog {
            .el-dialog__body {
                height: 500px;
                box-sizing: border-box;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
            .content {

            }
        }
    }
    .pages-center {
        margin-bottom: 20px;
    }
    .teacher-comments {
        display: flex;
        flex-direction: column;
        .comments {
            padding: 20px;
        }
        .textarea {
            padding-top: 20px;
        }
    }
    .pw-input {
        margin-right: 5px;
    }
